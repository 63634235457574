import { useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  type Entity,
  FETCH_SUBSCRIPTION_ENTITIES,
  getPaginationRequest,
  getSortRequest,
  useGridParams,
  useSubscriptionEntities,
  useSubscriptionEntitiesGridStore,
} from '@trustyou/shared';

import { AddCompetitors } from '../../../pages';
import { ManageNoEntities } from '../../NoData';
import EntitiesTable from '../../Organization/Entities/Common/EntitiesTable';

export const SubscriptionEntities = () => {
  const [selectedEntity, setSelectedEntity] = useState<Entity>();
  const { chosenSubscriptionId } = useParams();
  const gridState = useSubscriptionEntitiesGridStore();

  const { paginationModel, sortModel, searchKey, columnVisibilityModel, setColumnVisibilityModel } =
    gridState;

  const { onPaginationModelChange, onSortModelChange } = useGridParams(
    FETCH_SUBSCRIPTION_ENTITIES,
    gridState
  );

  const { data, isFetching } = useSubscriptionEntities(
    chosenSubscriptionId as string,
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel),
    searchKey
  );

  const onAddCompetitors = (entity: Entity) => setSelectedEntity(entity);
  const onCloseCompetitorsDialog = () => setSelectedEntity(undefined);

  if (data?.pagination?.total === 0 && !searchKey)
    return <ManageNoEntities subscriptionId={chosenSubscriptionId} />;

  return (
    <>
      <EntitiesTable
        data={data}
        isFetching={isFetching}
        paginationModel={paginationModel}
        sortModel={sortModel}
        searchKey={searchKey}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        onAddCompetitors={onAddCompetitors}
        shouldRefetchSubscription
      />
      {selectedEntity?.id && (
        <AddCompetitors entityId={selectedEntity?.id} onClose={onCloseCompetitorsDialog} />
      )}
    </>
  );
};
