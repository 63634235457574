import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import {
  faArrowDownToLine,
  faCheck,
  faEnvelope,
  faEnvelopeOpen,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, snackbar } from '@trustyou/ui';

import { STATUS_CHANGE_REFETCH_INTERVAL_MS } from '../../constants';
import { PAGINATED_REVIEWS } from '../../constants/query-keys';
import { exportReviews, useSetReviewReadStatus, useSetReviewRespondedStatus } from '../../hooks';
import useSetReviewsRespondedStatusPermission from '../../hooks/permissions/use-set-reviews-responded-status-permission';
import useSetReviewsStatusPermission from '../../hooks/permissions/use-set-reviews-status-permission';
import { IconButtonWithTooltip } from '../icon-button-with-tooltip';

const MAX_LIMIT_SELECTED_REVIEWS = 100;
const checkedListItemsCount = 3;

export function CustomBulkActions({
  selectedRows,
  resetSelectedRows,
}: {
  selectedRows: GridRowSelectionModel;
  resetSelectedRows: () => void;
}) {
  const queryClient = useQueryClient();
  const [isExporting, setIsExporting] = useState(false);
  const [isChangingStatus, setIsChangingStatus] = useState(false);
  const isSetReviewsStatusAllowed = useSetReviewsStatusPermission();
  const isSetReviewsRespondedStatusAllowed = useSetReviewsRespondedStatusPermission();
  const setReviewStatus = useSetReviewReadStatus();
  const setReviewRespondedStatus = useSetReviewRespondedStatus();

  const handleSuccess = () => {
    // ElasticSearch needs some milliseconds to re-gather the data, so we need a delay before refetch
    setTimeout(
      () => queryClient.invalidateQueries({ queryKey: [PAGINATED_REVIEWS] }),
      STATUS_CHANGE_REFETCH_INTERVAL_MS
    );
    resetSelectedRows();
    setIsChangingStatus(false);
  };

  const handleError = () => {
    snackbar.genericError();
    setIsChangingStatus(false);
  };

  const markAsReadInBulk = () => {
    setIsChangingStatus(true);
    setReviewStatus.mutate(
      {
        status: 'read',
        reviews: selectedRows as string[],
      },
      {
        onSuccess: handleSuccess,
        onError: handleError,
      }
    );
  };

  const markAsUnreadInBulk = () => {
    setIsChangingStatus(true);
    setReviewStatus.mutate(
      {
        status: 'unread',
        reviews: selectedRows as string[],
      },
      {
        onSuccess: handleSuccess,
        onError: handleError,
      }
    );
  };

  const markAsRespondedInBulk = () => {
    setIsChangingStatus(true);
    setReviewRespondedStatus.mutate({
      status: 'responded',
      reviews: selectedRows as string[],
    });
  };

  const exportSelectedReviews = async () => {
    setIsExporting(true);
    await exportReviews({
      filters: { ids: selectedRows as string[] },
      sort: {
        field: 'date',
        order: 'desc',
      },
      offset: 0,
    }).finally(() => {
      setIsExporting(false);
    });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButtonWithTooltip
        data-gtm-class="inbox_action_on_reviews"
        data-gtm-id="mark_as_unread"
        icon={faEnvelope}
        tooltip={
          isSetReviewsStatusAllowed ? (
            <FormattedMessage id="inbox.mark-as-unread" defaultMessage="Mark as unread" />
          ) : (
            <FormattedMessage
              id="inbox.user.roles.read.only.inbox.alert"
              defaultMessage="Your role is read only"
            />
          )
        }
        color="primary"
        onClick={markAsUnreadInBulk}
        disabled={isChangingStatus || !isSetReviewsStatusAllowed}
      />
      <IconButtonWithTooltip
        data-gtm-class="inbox_action_on_reviews"
        data-gtm-id="mark_as_read"
        icon={faEnvelopeOpen}
        tooltip={
          isSetReviewsStatusAllowed ? (
            <FormattedMessage id="inbox.mark-as-read" defaultMessage="Mark as read" />
          ) : (
            <FormattedMessage
              id="inbox.user.roles.read.only.inbox.alert"
              defaultMessage="Your role is read only"
            />
          )
        }
        color="primary"
        onClick={markAsReadInBulk}
        disabled={isChangingStatus || !isSetReviewsStatusAllowed}
      />
      <IconButtonWithTooltip
        data-gtm-class="inbox_action_on_reviews"
        data-gtm-id="mark_as_responded"
        icon={faCheck}
        tooltip={
          isSetReviewsRespondedStatusAllowed ? (
            <FormattedMessage
              id="inbox.action.mark-as-responded"
              defaultMessage="Mark as responded"
            />
          ) : (
            <FormattedMessage
              id="inbox.user.roles.read.only.inbox.alert"
              defaultMessage="Your role is read only"
            />
          )
        }
        color="primary"
        onClick={markAsRespondedInBulk}
        disabled={isChangingStatus || !isSetReviewsRespondedStatusAllowed}
      />
      <IconButtonWithTooltip
        data-gtm-class="inbox_action_on_reviews"
        data-gtm-id="export_selected"
        data-test-id="export-selected"
        icon={faArrowDownToLine}
        tooltip={
          checkedListItemsCount > MAX_LIMIT_SELECTED_REVIEWS ? (
            <FormattedMessage
              id="inbox.table.action.export.selected.tooltip.maxLimit"
              defaultMessage="Maximum selection for individual download: 100 reviews. Deselect some or use “Export all” on the right"
            />
          ) : (
            <FormattedMessage
              id="inbox.table.action.export.selected.tooltip"
              defaultMessage="Export selected reviews to CSV (max. 100)"
            />
          )
        }
        color="primary"
        disabled={checkedListItemsCount > MAX_LIMIT_SELECTED_REVIEWS || isExporting}
        onClick={exportSelectedReviews}
      />
    </Box>
  );
}
