import { type SyntheticEvent, useEffect, useState } from 'react';
import type { DropResult } from 'react-beautiful-dnd';

import { useInboxStore, useLanguageStore, useMembershipStore } from '@trustyou/shared';
import { useDrawer } from '@trustyou/ui';

import { FiltersTabContent } from './filters-tab-content';
import { ManageTabContent } from './manage-tab-content';
import { ViewsDrawer } from './views-drawer';
import { ViewsDropdown } from './views-dropdown';

import type { SemaLanguage } from '../../client';
import {
  useFetchSemaLanguages,
  useFetchSources,
  useFetchSurveyTypes,
  useFetchViews,
  useReviewsInfo,
  useSaveViews,
} from '../../hooks';
import { usePredefinedViews } from '../../hooks/use-predefined-views';
import { DrawerTabs, type FiltersForm, type View } from '../../types';
import { countActiveFilters } from '../../utils/filters';
import { getViewFilters } from '../../utils/mappers';
import { FiltersButton } from '../filters-button/filters-button';

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function Views() {
  const { hasReviews } = useReviewsInfo();

  const { isOpen, openDrawer, closeDrawer } = useDrawer();
  const [currentTab, setCurrentTab] = useState<DrawerTabs>(DrawerTabs.MANAGE);

  const { allReviewsView, lastSevenDaysView } = usePredefinedViews();
  const currentView = useInboxStore((state) => state.currentView);
  const setCurrentView = useInboxStore((state) => state.setCurrentView);
  const { data: views = [] } = useFetchViews();
  const saveViews = useSaveViews();
  const { membership } = useMembershipStore();

  const { data: sources = {} } = useFetchSources();
  const { locale } = useLanguageStore();
  const { data: languages = {} } = useFetchSemaLanguages(locale as SemaLanguage);
  const { data: surveyTypes = [] } = useFetchSurveyTypes();
  const activeFiltersCount = countActiveFilters(currentView.filters ?? {}, sources, languages);

  useEffect(() => {
    // clear filter when organization changes
    if (membership?.id !== currentView.membership_id) {
      handleApplyFilters(lastSevenDaysView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membership?.id]);

  const handleFilter = (view: View) => {
    if (view.filters && !view.filters?.relative_timerange && !view.filters?.preset_timerange) {
      view.filters.relative_timerange = 'all-time';
    }
    setCurrentView({ ...view, membership_id: membership?.id });
  };

  const normalizeFilters = (search_terms: string[], view: View) => {
    const allReviewsViewWithSearchTerms = {
      ...allReviewsView,
      filters: { ...allReviewsView.filters, search_terms },
    };
    const customViewWithChangedFilters = {
      ...view,
      filters: {
        ...view?.filters,
        search_terms,
      },
    };
    const normalizedView = view ? customViewWithChangedFilters : allReviewsViewWithSearchTerms;
    return normalizedView;
  };

  const handleApplyFilters = (newView: View) => {
    handleFilter(normalizeFilters(currentView.filters?.search_terms ?? [], newView));
  };

  // Apply filters "on-the-fly" (without saving them into a View)
  const applyFilters = (form: FiltersForm) => {
    const filters = getViewFilters(
      form,
      Object.keys(sources)?.length,
      Object.keys(languages)?.length,
      surveyTypes?.length
    );
    const viewToApply = {
      label: '',
      filters: { ...filters, search_terms: currentView.filters?.search_terms },
    };
    handleApplyFilters(viewToApply);
    closeDrawer();
  };

  const handleOpenDrawer = (tab: DrawerTabs) => () => {
    setCurrentTab(tab);
    openDrawer();
  };

  const handleTabChange = (event: SyntheticEvent<Element, Event>, value: DrawerTabs) => {
    setCurrentTab(value);
  };

  const handleReorder = (result: DropResult) => {
    if (!result.destination) return;
    const newOrder = reorder(views, result.source.index, result.destination.index);
    saveViews.mutate(newOrder);
  };

  const handleDelete = (viewToDelete: View) => {
    handleApplyFilters(lastSevenDaysView);
    const viewsWithoutTheOneToDelete = views.filter((view) => view.label !== viewToDelete.label);
    saveViews.mutate(viewsWithoutTheOneToDelete);
  };

  const handleClearFilters = () => {
    handleApplyFilters(allReviewsView);
  };

  const handleClickFiltersButton = () => {
    handleOpenDrawer(DrawerTabs.FILTERS)();
  };

  return (
    <>
      <ViewsDropdown handleManage={handleOpenDrawer(DrawerTabs.MANAGE)} />
      <FiltersButton
        count={activeFiltersCount}
        isDisabled={!hasReviews}
        onClick={handleClickFiltersButton}
        onClear={handleClearFilters}
      />
      <ViewsDrawer
        isOpen={isOpen}
        onClose={closeDrawer}
        currentTab={currentTab}
        onChange={handleTabChange}
        onApplyFilters={applyFilters}
        ManageTabContent={
          <ManageTabContent views={views} onReorder={handleReorder} onDelete={handleDelete} />
        }
        FiltersTabContent={<FiltersTabContent onClose={closeDrawer} />}
      />
    </>
  );
}
