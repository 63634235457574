import { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import type { GridCellParams } from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';
import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  CONNECT_PROVIDER_NAMES,
  CREATE_BOOKING_CONNECTION,
  FETCH_REVIEW_PROVIDER_DETAILS,
  commonFields,
  getDisplayedProviderName,
  getPaginationRequest,
  stringToDateAndTime,
  useDeleteProviderAccount,
  useDisconnectProvider,
  useEntityCount,
  useGridParams,
  useProviderAccounts,
  useResponsive,
  useReviewProviderDetails,
  useReviewProviderDetailsGridStore,
  useRouteUtils,
} from '@trustyou/shared';
import {
  Box,
  Button,
  CircularProgress,
  ContentPane,
  DataGrid,
  EmptyReviewProviderDetails,
  FullScreenModal,
  ReviewProviderAPIStatus,
  StyledFontAwesomeIcon,
  Typography,
  snackbar,
} from '@trustyou/ui';
import { capitalize } from 'lodash';

import { DetailsCell } from './DetailsCell';
import DisconnectAPIModal from './DisconnectAPIModal';
import { useInboxDelayTimeMessage } from './InboxDelay';
import ProviderAccountView from './ProviderAccountView';
import RemoveAccountModal from './RemoveAccountModal';

import BookingConnectManager from '../BookingConnect/BookingConnectManager';
import GoogleConnectManager from '../GoogleConnect/GoogleConnectManager';
import styles from './styles';

const tableHeaders = defineMessages({
  google: {
    id: 'reviewProviderDetails.table.header.google',
    defaultMessage: 'Google Business Profile',
  },
  cxpEquivalent: {
    id: 'reviewProviderDetails.table.header.cxpEquivalent',
    defaultMessage: 'CXP equivalent',
  },
  lastSynced: {
    id: 'reviewProviderDetails.table.header.lastSynced',
    defaultMessage: 'Last synced',
  },
  propertyId: {
    id: 'reviewProviderDetails.table.propertyId',
    defaultMessage: 'Property ID',
  },
  placeId: {
    id: 'reviewProviderDetails.table.placeId',
    defaultMessage: 'Place ID',
  },
});

const actionMessages = defineMessages({
  disconnectAPI: {
    id: 'review-providers.disconnect-api.success',
    defaultMessage:
      'APIs disconnected. It might take {delay} for the changes to reflect in your inbox.',
  },
  deleteAccount: {
    id: 'review-providers.delete-account.success',
    defaultMessage:
      'Account removed. It might take {delay} for the changes to reflect in your inbox.',
  },
});

const ReviewProviderDetails = () => {
  const intl = useIntl();
  const inboxDelayTimeMessage = useInboxDelayTimeMessage();
  const disconnectAPIMessage = intl.formatMessage(actionMessages.disconnectAPI, {
    delay: inboxDelayTimeMessage,
  });
  const deleteAccountMessage = intl.formatMessage(actionMessages.deleteAccount, {
    delay: inboxDelayTimeMessage,
  });

  const { isMobile } = useResponsive();
  const { reviewProviderId = '' } = useParams();
  const { goUp, navigateWithBase } = useRouteUtils();
  const { data: totalEntities } = useEntityCount();

  const gridState = useReviewProviderDetailsGridStore();

  const queryClient = useQueryClient();
  const bookingConnectStatus = queryClient.getQueryData([CREATE_BOOKING_CONNECTION]) as
    | string
    | undefined;

  const [deletedProviderName, setDeletedProviderName] = useState<string | undefined>(undefined);
  const [deletedAccountId, setDeletedAccountId] = useState<string | undefined>(undefined);

  const [bookingConnectDialogOpen, setBookingConnectDialogOpen] = useState(false);
  const openBookingConnectDialog = () => setBookingConnectDialogOpen(true);
  const closeBookingConnectDialog = () => setBookingConnectDialogOpen(false);

  const [googleConnectDialogOpen, setGoogleConnectDialogOpen] = useState(false);
  const openGoogleConnectDialog = () => setGoogleConnectDialogOpen(true);
  const closeGoogleConnectDialog = () => setGoogleConnectDialogOpen(false);

  const { paginationModel } = gridState;
  const { onPaginationModelChange } = useGridParams(FETCH_REVIEW_PROVIDER_DETAILS, gridState);

  const { data: providerAccounts, isPending: isInitialAccountLoading } =
    useProviderAccounts(reviewProviderId);

  const {
    data: reviewProviderDetails,
    isFetching: isLoadingDetails,
    isLoading: isInitialDetailsLoading,
  } = useReviewProviderDetails(reviewProviderId, getPaginationRequest(paginationModel));

  const disconnectProvider = useDisconnectProvider();
  const deleteProviderAccount = useDeleteProviderAccount();

  useEffect(() => {
    gridState.resetGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const providerName = reviewProviderDetails?.review_provider.name;

  const handleOpenDisconnectProviderModal = () => setDeletedProviderName(providerName);
  const handleCloseDisconnectModal = () => setDeletedProviderName(undefined);

  const onDisconnectProvider = () =>
    disconnectProvider.mutate(reviewProviderDetails?.review_provider.id as string, {
      onSuccess: () => {
        snackbar.success(disconnectAPIMessage);
      },
      onSettled: () => {
        navigateWithBase('settings/application/review-providers');
      },
    });

  const handleOpenRemoveAccountModal = (providerAccountId: string) =>
    setDeletedAccountId(providerAccountId);

  const handleCloseRemoveAccountModal = () => setDeletedAccountId(undefined);

  const onRemoveAccount = (providerAccountId?: string) =>
    deleteProviderAccount.mutate(providerAccountId as string, {
      onSuccess: () => {
        snackbar.info(providerAccounts?.length === 0 ? disconnectAPIMessage : deleteAccountMessage);
      },
      onSettled: handleCloseRemoveAccountModal,
    });

  const isGoogle = providerName === CONNECT_PROVIDER_NAMES.GOOGLE;
  const isBooking = providerName === CONNECT_PROVIDER_NAMES.BOOKING;

  const displayedProviderName = getDisplayedProviderName(providerName);

  const handleAddNewAccount = () => {
    if (isGoogle) {
      openGoogleConnectDialog();
    }
    if (isBooking) {
      openBookingConnectDialog();
    }
  };

  const columns = [
    {
      field: 'source',
      headerName: isGoogle
        ? intl.formatMessage(tableHeaders.google)
        : capitalize(displayedProviderName),
      renderCell: ({ row }: GridCellParams) => (
        <DetailsCell
          primaryValue={row.provider_entity.name}
          secondaryValue={`${intl.formatMessage(
            isGoogle ? tableHeaders.placeId : tableHeaders.propertyId
          )}:${row.provider_entity.review_provider_entity_id}`}
        />
      ),
      sortable: false,
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'cxpEquivalent',
      headerName: intl.formatMessage(tableHeaders.cxpEquivalent),
      renderCell: ({ row }: GridCellParams) => (
        <DetailsCell primaryValue={row.entity.name} secondaryValue={row.entity.address} />
      ),
      sortable: false,
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'status',
      headerName: intl.formatMessage(commonFields.status),
      renderCell: ({ row }: GridCellParams) => <ReviewProviderAPIStatus status={row.status} />,
      sortable: false,
      flex: 1,
    },
    {
      field: 'last_used_at',
      headerName: intl.formatMessage(tableHeaders.lastSynced),
      renderCell: ({ row }: GridCellParams) =>
        row.last_used_at ? stringToDateAndTime(row.last_used_at) : null,
      sortable: false,
      flex: 1,
    },
  ];

  const rows = (reviewProviderDetails?.data || []).map((row, id) => ({
    ...row,
    id,
  }));

  const isLoadingState = isInitialDetailsLoading || isInitialAccountLoading;

  if (isLoadingState)
    return (
      <ContentPane
        sx={styles.pageContainer}
        headers={
          isMobile
            ? undefined
            : [<Typography variant="h6">{capitalize(displayedProviderName)}</Typography>]
        }
        navigationHandle={goUp}
      >
        <Box sx={styles.initialLoadingWrapper}>
          <CircularProgress color="primary" />
        </Box>
      </ContentPane>
    );

  return (
    <ContentPane
      sx={styles.pageContainer}
      headers={
        isMobile
          ? undefined
          : [<Typography variant="h6">{capitalize(displayedProviderName)}</Typography>]
      }
      navigationHandle={goUp}
    >
      <Button
        size="large"
        sx={styles.disconnectAPIButton}
        data-testid="disconnect-api"
        data-gtm-id={`disconnect-api-${displayedProviderName}`}
        onClick={handleOpenDisconnectProviderModal}
        variant="outlined"
      >
        <FormattedMessage
          id="review-providers.details.button.disconnect-api"
          defaultMessage="Disconnect APIs"
        />
      </Button>
      <Box sx={styles.providerAccountSection}>
        <Typography variant="subtitle1">
          <FormattedMessage
            id="review-providers.details.accounts.title"
            defaultMessage="{accountsCount, plural,
          one {Connected Account}
          other {Connected Accounts}
        }"
            values={{ accountsCount: providerAccounts?.length }}
          />
          {/* <FormattedMessage
            id="review-providers.provider-account.title"
            defaultMessage="Connected Accounts"
          /> */}
        </Typography>
        <Box sx={styles.providerAccountList}>
          {providerAccounts &&
            providerAccounts.map((account) => (
              <ProviderAccountView
                key={account.provider_account_id}
                account={account}
                onDelete={handleOpenRemoveAccountModal}
              />
            ))}

          <Button
            variant="text"
            startIcon={<StyledFontAwesomeIcon icon={faPlus} />}
            color="primary"
            size="large"
            data-testid="add-new-provider-account"
            data-gtm-id={`add-new-provider-account-for-${displayedProviderName}`}
            onClick={handleAddNewAccount}
          >
            <FormattedMessage
              id="review-providers.add-account-button.text"
              defaultMessage="Add account"
            />
          </Button>
        </Box>
      </Box>
      {rows.length > 0 ? (
        <>
          <Typography variant="subtitle1" fontWeight={500} marginBottom={2}>
            {totalEntities && totalEntities > 1 ? (
              <FormattedMessage
                id="review-providers.connected.entities"
                defaultMessage="{connected} out of {total} entities connected"
                values={{
                  connected: reviewProviderDetails?.pagination.total,
                  total: totalEntities,
                }}
              />
            ) : (
              <FormattedMessage
                id="review-providers.connected.entity"
                defaultMessage="1 entity connected"
              />
            )}
          </Typography>
          <DataGrid
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            rowCount={reviewProviderDetails?.pagination.total}
            rowHeight={64}
            columns={columns}
            rows={rows}
            loading={isLoadingDetails}
          />
        </>
      ) : (
        <EmptyReviewProviderDetails reviewProviderName={capitalize(providerName)} />
      )}
      <DisconnectAPIModal
        providerName={deletedProviderName}
        displayedProviderName={displayedProviderName}
        onClose={handleCloseDisconnectModal}
        onDelete={onDisconnectProvider}
        isDisconnectInProgress={disconnectProvider.isPending}
      />
      <RemoveAccountModal
        providerAccountId={deletedAccountId}
        providerAccountCount={providerAccounts?.length || 0}
        onClose={handleCloseRemoveAccountModal}
        onDelete={() => onRemoveAccount(deletedAccountId)}
        isDeleteInProgress={deleteProviderAccount.isPending}
        displayedProviderName={displayedProviderName}
      />
      <FullScreenModal
        open={bookingConnectDialogOpen}
        onClose={closeBookingConnectDialog}
        noHeaderBorder
        disableEscapeKeyDown
        closeButtonGTMID={bookingConnectStatus}
      >
        <BookingConnectManager onClose={closeBookingConnectDialog} />
      </FullScreenModal>
      <FullScreenModal
        open={googleConnectDialogOpen}
        onClose={closeGoogleConnectDialog}
        noHeaderBorder
        disableEscapeKeyDown
      >
        <GoogleConnectManager />
      </FullScreenModal>
    </ContentPane>
  );
};

export default ReviewProviderDetails;
