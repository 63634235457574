import type { DashboardFilter } from '../types';

export const getDashboardExcludedFilters = (filters: DashboardFilter[]) => {
  const excludedFilters = filters
    .filter(
      (row) =>
        row.jaql && (row.psHideFilter || /^segment\d$/.test(row.jaql.column || row.jaql.title))
    )
    .map((row) => row.jaql.column || row.jaql.title);
  return JSON.stringify(excludedFilters);
};
