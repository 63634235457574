import type { EntityCustomAttributes } from './customAttribute';
import type { Organization } from './organization';

import type { Pagination, PaginationResponse } from './shared';
import type { SourceUrl } from './source';

export interface Entity {
  id: string;
  name: string;
  sector: string;
  brand: string;
  country: string;
  country_name: string;
  state: string;
  zip: string;
  city: string;
  address: string;
  continent: string;
  custom_attributes: EntityCustomAttributes;
  competitors?: Entity[];
  sources_urls?: SourceUrl[];
  created_at?: string;
  updated_at?: string;
  organizations?: Organization[];
}

export type BasicEntity = Pick<Entity, 'id' | 'name' | 'country_name' | 'city'>;

export interface MatchedEntity {
  input: Entity;
  entity_id: Entity['id'];
}

export interface EntityFieldError {
  field: string;
  message: string;
  code: string;
}

export interface ErrorEntity {
  input: Entity;
  errors: EntityFieldError[];
}

export interface PreviewEntityData {
  matched: MatchedEntity[];
  unmatched: Entity[];
  errors: ErrorEntity[];
}

export interface PreviewEntityRow extends Entity {
  rowId: string;
  entityId?: string;
  status: string;
  errorColumns?: string[];
}

export interface UploadEntityUpdated {
  entity: Entity;
  diff: object;
}

export interface UploadEntityErrored {
  entity: Entity;
  error: string;
}

export interface EntityUploadResponse {
  added: Entity[];
  updated: UploadEntityUpdated[];
  skipped: Entity[];
  errored: UploadEntityErrored[];
}

export interface EntityUploadErrorResponse {
  error: { code: string; message: string };
}

export interface EntitiesErrored {
  [key: string]: string;
}

export interface CompetitorsUploadResponse {
  entities_set: string[];
  entities_errored: EntitiesErrored;
}

export interface CompetitorsUploadErrorResponse {
  error: { code: string; message: string };
}

export interface PreviewEntitiesSummary {
  [key: string]: number;
}

interface NewEntity {
  entity_id: Entity['id'];
  attributes: EntityCustomAttributes;
}

export interface EntitiesPayload {
  subscription_id?: string;
  entities: NewEntity[];
}

export interface EntitiesResponse {
  data: Entity[];
  pagination: Pagination;
}

export interface EntityShortResponse {
  data: BasicEntity[];
  pagination: Pagination;
}

export interface SetCompetitorsPayload {
  organization_id: string;
  entity_id: Entity['id'];
  competitors: string[];
}

// export interface EntityFormData {
//   name: string;
//   sector: string;
//   brand: string;
//   continent: string;
//   country: string;
//   city: string;
//   address: string;
//   state?: string;
//   zip?: string;
// }
export type EntityFormData = Pick<
  Entity,
  'name' | 'sector' | 'brand' | 'continent' | 'country' | 'city' | 'address'
> & {
  state?: Entity['state'];
  zip?: Entity['zip'];
};

export type CreateEntity = EntityFormData;

export interface CreateEntityResponse extends EntityFormData {
  id: Entity['id'];
  created_at: string;
  updated_at: string;
}

export type EditEntityResponse = CreateEntityResponse;

export interface EditEntity extends CreateEntity {
  id: Entity['id'];
}

export enum GlobalEntitiesViewTypes {
  CREATE = 'create',
  EDIT = 'edit',
  LIST = 'list',
  ENTITY_DETAILS = 'entity_details',
}

export interface GlobalEntity extends Entity {
  organizations: Organization[];
}

export type GlobalEntityResponse = PaginationResponse<GlobalEntity>;

export enum ENTITY_STATUS {
  PENDING_APPROVAL = 'pending_approval',
  ACTIVE = 'active',
  REJECTED = 'rejected',
}
